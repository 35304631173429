import { Dialog, makeStyles } from '@material-ui/core';

export default function ConfirmDialog(props) {
    const { confirmDialog, setConfirmDialog, deleteItem } = props;
    const classes = useStyles();
    return (

        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <div className={classes.dialogTitle}>
                {confirmDialog.title}
            </div>
            <div className={classes.dialogContent}>
                {confirmDialog.subTitle}
            </div>
            <div className={classes.dialogAction}>
                <div className={classes.buttonActionNo} onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>
                    <span>Hủy</span>
                </div>
                <div className={classes.buttonActionYes} onClick={deleteItem}>
                    <span>Đồng ý!</span>
                </div>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialog: {
        position: 'absolute'
        , top: '100px'
    },
    dialogTitle: {
        textAlign: 'center'
        , background: '#003c70'
        , color: 'white'
        , fontSize: '25px'
        , padding: '5px 0px 5px 0px'
    },
    dialogContent: {
        textAlign: 'center'
        , padding: '20px'
        , fontSize: '20px'
    },
    dialogAction: {
        justifyContent: 'center'
        , display: 'flex'
        , padding: '10px 0px 20px 0px'
        , color: 'white'
    },
    buttonActionNo: {
        borderRadius: '5px'
        , backgroundColor: 'royalblue'
        , padding: '5px 25px 5px 25px'
        , marginRight: '10px'
        , '&:hover': {
            cursor: 'pointer'
        }
    },
    buttonActionYes: {
        borderRadius: '5px'
        , background: 'crimson'
        , padding: '5px 25px 5px 25px'
        , marginLeft: '10px'
        , '&:hover': {
            cursor: 'pointer'
        }
    }
}))