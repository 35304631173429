import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import { useHistory, useLocation } from 'react-router-dom';
import { $api } from 'services/service';
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert, setLoading } from 'redux/authSlice';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import Button from 'components/Elements/Button/Button';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import TableScheduleSubject from 'components/Elements/Table/Table-Schedule-Subject';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import { _AUTH } from '../../../constants/_auth';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Schedule__Subject() {
    let role = localStorage.getItem(_AUTH.ROLE) !== null && localStorage.getItem(_AUTH.ROLE) != 'Teacher';
    let history = useHistory();
    const query = useQuery();
    let idSubject: any = query.get('id');
    const dispatch = useDispatch();
    const [graduated, setGraduated] = useState('0');
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [schedule, setSchedule] = useState([]);
    const [subjectDetail, setSubjectDetail] = useState({
        creditPoint: 0,
        facultyId: '',
        facultyName: '',
        id: '',
        name: '',
        subjectCode: ''
    });
    const [subjectTeaching, setSubjectTeaching] = useState({
        id: '',
        subjectId: idSubject,
        name: '',
        startDate: new Date(),
        endDate: new Date(),
        startTime: '00:00',
        teacherId: '',
        roomIdDefault: '',
        totalSessions: 0
    });
    const [updateMode, setUpdateMode] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalNote, setModelNote] = useState({ isOpen: false, subject: {
            name: '',
            notes: []
        } });
    const [teachers, setTeachers] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [changes, setChanges] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });
    const [confirmSubjectNoteDialog, setConfirmSubjectNoteDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });
    const [idDelete, setIdDelete] = useState(null);
    const [idSubjectNoteDelete, setIdSubjectNoteDelete] = useState(null);

    useEffect(() => {
        async function getSubjectsDetail() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.subject.getDetail(idSubject);
                if (res && res.result) {
                    subjectDetail.creditPoint = res.result.creditPoint;
                    subjectDetail.facultyId = res.result.facultyId;
                    subjectDetail.facultyName = res.result.facultyName;
                    subjectDetail.id = res.result.id;
                    subjectDetail.name = res.result.name;
                    subjectDetail.subjectCode = res.result.subjectCode;
                    setSubjectDetail(subjectDetail);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        //
        getSubjectsDetail();
    }, []);

    useEffect(() => {
        getSchedule({
            freeTextSearch: search || search.length > 0 ? search : null,
            pageIndex: page,
            pageSize: 9,
            subjectId: idSubject,
            startDateFrom: null,
            startDateTo: null
        });
    }, [changes]);

    async function getSchedule(option = {}) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_getPaging(
                option
            );
            if (res && res.result) {
                setTotal(res.result.totalRecord);
                setSchedule(res.result.items);
                setPages(Math.ceil(res.result.totalRecord / 9));
                dispatch(setLoading(false));
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true,
            subTitle: msg
        });
    }

    useEffect(() => {
        async function getTeachers() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.teacher.getAll();
                setTeachers(res.result.items);
                if (res.result.length !== 0) {
                    setTeachers(res.result);
                    subjectTeaching.teacherId = res.result[0].id;
                    setSubjectTeaching({
                        ...subjectTeaching
                    });
                } else {
                    setTeachers([]);
                    setSubjectTeaching({
                        ...subjectTeaching,
                        teacherId: ''
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        async function getRooms() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.room.getAll();
                if (res['result'].length !== 0) {
                    setRooms(res.result);
                    setSubjectTeaching({
                        ...subjectTeaching,
                        roomIdDefault: res.result[0].id
                    });
                } else {
                    setSubjectTeaching({
                        ...subjectTeaching,
                        roomIdDefault: ''
                    });
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getRooms();
        getTeachers();
    }, []);

    async function openCreateSubject(e) {
        e.stopPropagation();
        try {
            subjectTeaching.name = '';
            subjectTeaching.subjectId = idSubject;
            subjectTeaching.startDate = new Date();
            subjectTeaching.endDate = new Date();
            // @ts-ignore
            subjectTeaching.teacherId = teachers[0].id;
            // @ts-ignore
            subjectTeaching.roomIdDefault = rooms[0].id;
            setSubjectTeaching(subjectTeaching);
            setUpdateMode(null);
            setModalShow(true);
        } catch (e) {
            if (teachers.length === 0) {
                show_ErrMsg('Khoa hiện tại chưa có giảng viên vui lòng tạo giảng viên!');
            }
        }
    }

    // function selectedTeacher(teacher) {
    // 	setSubjectTeaching({
    // 		...subjectTeaching,
    // 		// @ts-ignore
    // 		teacherId: teacher.id,
    // 	});
    // }

    // function selectedRoom(room) {
    // 	setSubjectTeaching({
    // 		...subjectTeaching,
    // 		// @ts-ignore
    // 		roomIdDefault: room.id,
    // 	});
    // }

    function onSave() {
        if (subjectTeaching.name === '') {
            show_ErrMsg('Vui lòng tên lịch học');
            return;
        }
        if (subjectTeaching.totalSessions.toString() === '' || subjectTeaching.totalSessions === 0) {
            show_ErrMsg('Vui lòng nhập tổng số buổi dự kiến');
            return;
        }
        // @ts-ignore
        if (subjectTeaching.teacherId === '' && teachers[0].id === '') {
            show_ErrMsg('Vui lòng chọn giảng viên');
            return;
        }
        // @ts-ignore
        if (subjectTeaching.roomIdDefault === '' && rooms[0].id === '') {
            show_ErrMsg('Vui lòng chọn phòng học');
            return;
        }
        if (subjectTeaching.endDate < subjectTeaching.startDate) {
            show_ErrMsg('Thời gian kết thúc không hợp lệ');
            return;
        }
        if (updateMode !== null) {
            is_Update();
        } else {
            is_Insert();
        }
    }

    // function setDatWithTime(date, time) {
    // 	const d = new Date(date);
    // 	const hour = time.split(':')[0] || 9;
    // 	const min = time.split(':')[1] || 0;
    // 	return new Date(
    // 		d.getFullYear(),
    // 		d.getMonth(),
    // 		d.getDate(),
    // 		Number(hour),
    // 		Number(min),
    // 		0
    // 	);
    // }

    async function is_Insert() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_create(
                subjectTeaching
            );
            if (res) {
                dispatch(
                    setAlert({
                        title: `Tạo lịch học thành công`
                    })
                );
            }
            setModalShow(false);
            subjectTeaching.name = '';
            subjectTeaching.subjectId = idSubject;
            subjectTeaching.startDate = new Date();
            subjectTeaching.endDate = new Date();
            subjectTeaching.teacherId = '';
            subjectTeaching.roomIdDefault = '';
            subjectTeaching.totalSessions = 0;
            setSubjectTeaching(subjectTeaching);
            setChanges(!changes);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function is_Update() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_update(
                subjectTeaching
            );
            if (res) {
                dispatch(
                    setAlert({
                        title: `Cập nhật lịch học thành công`
                    })
                );
            }
            setModalShow(false);
            subjectTeaching.name = '';
            subjectTeaching.subjectId = idSubject;
            subjectTeaching.startDate = new Date();
            subjectTeaching.endDate = new Date();
            subjectTeaching.teacherId = '';
            subjectTeaching.roomIdDefault = '';
            subjectTeaching.totalSessions = 0;
            setSubjectTeaching(subjectTeaching);
            setChanges(!changes);
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function deleteScheduleSubject(e, id) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true,
            title: 'Thông báo',
            subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDelete(id);
    }

    async function deleteItem() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectTeaching_delete(
                idDelete
            );
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                setChanges(!changes);
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function updateSchedule(e, item) {
        e.stopPropagation();
        try {
            subjectTeaching.id = item.id;
            subjectTeaching.name = item.name;
            subjectTeaching.subjectId = item.subjectId;
            // subjectTeaching.totalSessions = item.totalSessions;
            // subjectTeaching.startDate = new Date(item.startDate + 'Z');
            subjectTeaching.startDate = moment(
                item.startDate,
                'DD/MM/YYYY'
            ).isValid()
                ? moment(item.startDate, 'DD/MM/YYYY').toDate()
                : item.startDate;
            subjectTeaching.endDate = moment(
                item.endDate,
                'DD/MM/YYYY'
            ).isValid()
                ? moment(item.endDate, 'DD/MM/YYYY').toDate()
                : item.endDate;
            subjectTeaching.teacherId = item.teacherId;
            subjectTeaching.totalSessions = item.totalSessions;
            subjectTeaching.roomIdDefault = item.roomIdDefault;
            setSubjectTeaching(subjectTeaching);
            setUpdateMode(item.id);
            setModalShow(true);
        } catch (e) {
        }
    }

    function getScheduleDetails(e, id) {
        e.stopPropagation();
        history.push(`/lich-hoc-mon-chitiet/${id}`);
    }

    function noteAction(e, subject) {
        e.stopPropagation();
        console.log(subject);
        setModelNote({ isOpen: true, subject: subject });
    }

    function removeSubjectNote(e, id) {
        e.stopPropagation();
        setConfirmSubjectNoteDialog({
            isOpen: true,
            title: 'Thông báo',
            subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdSubjectNoteDelete(id);
    }

    async function deleteScheduleSubjectNote() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.schedule.subjectSchedules_DeleteNote({
                id: idSubjectNoteDelete
            });
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                setModelNote({ isOpen: false, subject: {name: '', notes: []} });
                setConfirmSubjectNoteDialog({isOpen: false, title: '', subTitle: ''})
                setChanges(!changes);
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <a href={`/thoi-khoa-bieu`}>Danh sách môn học</a>
                        <div className='___title__box__last'>{`>`}</div>
                        <div>{subjectDetail.name}</div>
                    </div>
                </div>
                <br />
                {
                    role ? <div
                        className='actions--item page__content bg-white mt-3 '>
                        <Button
                            label='Thêm lịch học'
                            click={(e) => openCreateSubject(e)}
                            icon={<i className='fas fa-chalkboard-teacher' />}
                        />
                        <a href={`/quan-ly-bo-de/` + subjectDetail.id} className={'ml-1 '}>
                            <Button
                                label='Thêm bộ đề'
                                color={'bg-green-800'}
                                hoverColor={'bg-green-600'}
                                onClick={() => window.location.href = '/thoi-khoa-bieu'}
                                icon={<i className='fas fa-chalkboard-teacher' />}
                            />
                        </a>
                    </div> : ''
                }
                {schedule.length === 0 ? (
                    <div
                        className='faculties--list flex flex-wrap -ml-4 -mb-4'
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <img
                            src='/notFound_01.png'
                            alt='Không tìm thấy dữ liệu'
                            title='Không tìm thấy dữ liệu'
                        />
                    </div>
                ) : (
                    <div className='w-auto-scroll'>
                        <div className='table w-auto-scroll mt-8 w-full'>
                            <TableScheduleSubject
                                data={schedule}
                                deleteClicked={deleteScheduleSubject}
                                updateClicked={updateSchedule}
                                clicked={getScheduleDetails}
                                noteClick={noteAction}
                            />
                        </div>
                    </div>
                )}

                <div>
                    <Modal
                        show={modalShow}
                        aria-labelledby='contained-modal-title-vcenter'
                        onHide={() => setModalShow(false)}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id='contained-modal-title-vcenter'>
                                {updateMode
                                    ? 'Cập nhật lịch học'
                                    : 'Tạo mới lịch học'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Tên lịch học
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='appearance-none block
										w-full px-3 py-2 border border-gray-300
										placeholder-gray-500 rounded-md
										focus:outline-none focus:ring-1
										focus:ring-blue-700'
                                    placeholder='Nhập tên lịch học'
                                    value={subjectTeaching.name || ''}
                                    onChange={(e) => {
                                        setSubjectTeaching({
                                            ...subjectTeaching,
                                            name: e.target.value
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Tổng số buổi học dự kiến
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    className='appearance-none block
										w-full px-3 py-2 border border-gray-300
										placeholder-gray-500 rounded-md
										focus:outline-none focus:ring-1
										focus:ring-blue-700'
                                    placeholder='Nhập tổng số buổi học dự kiến'
                                    value={subjectTeaching.totalSessions || ''}
                                    onChange={(e) => {
                                        setSubjectTeaching({
                                            ...subjectTeaching,
                                            totalSessions: Number.parseInt(e.target.value)
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Ngày bắt đầu
                                </div>
                                <DatePicker
                                    className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    selected={
                                        new Date(subjectTeaching.startDate)
                                    }
                                    onChange={(date) => {
                                        setSubjectTeaching({
                                            ...subjectTeaching,
                                            startDate: date
                                        });
                                    }}
                                    dateFormat='dd/MM/yyyy'
                                />
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Ngày kết thúc
                                </div>
                                <DatePicker
                                    className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    selected={new Date(subjectTeaching.endDate)}
                                    onChange={(date) => {
                                        setSubjectTeaching({
                                            ...subjectTeaching,
                                            endDate: date
                                        });
                                    }}
                                    minDate={
                                        new Date(subjectTeaching.startDate)
                                    }
                                    dateFormat='dd/MM/yyyy'
                                />
                            </div>
                            {teachers && teachers.length > 0 ? (
                                <div className='mt-2'>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Giảng viên
                                    </div>
                                    <select
                                        className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                        // @ts-ignore
                                        value={subjectTeaching.teacherId}
                                        onChange={(e) => {
                                            setSubjectTeaching({
                                                ...subjectTeaching,
                                                // @ts-ignore
                                                teacherId: e.target.value
                                            });
                                        }}>
                                        {teachers.map((item: any) => {
                                            return (
                                                <option
                                                    value={item.id}
                                                    key={item.id}>
                                                    {item.fullName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {/* <List data={teachers} selected={selectedTeacher} /> */}
                                </div>
                            ) : (
                                ''
                            )}
                            {rooms && rooms.length > 0 ? (
                                <div className='mt-2'>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Phòng học
                                    </div>
                                    <select
                                        className='input-custom block
												w-full px-3 py-2 border border-gray-300
												placeholder-gray-500 rounded-md
												focus:outline-none focus:ring-1
												focus:ring-blue-700'
                                        // @ts-ignore
                                        value={subjectTeaching.roomIdDefault}
                                        onChange={(e) => {
                                            setSubjectTeaching({
                                                ...subjectTeaching,
                                                // @ts-ignore
                                                roomIdDefault: e.target.value
                                            });
                                        }}>
                                        {rooms.map((item: any) => {
                                            return (
                                                <option
                                                    value={item.id}
                                                    key={item.id}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            ) : (
                                ''
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div
                                onClick={() => {
                                    onSave();
                                }}>
                                <Button
                                    label={updateMode ? 'Lưu lại' : 'Tạo mới'}
                                />
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div>
                    <Modal
                        show={modalNote.isOpen}
                        aria-labelledby='contained-modal-title-vcenter'
                        onHide={() => setModelNote({ isOpen: false, subject: {name: '', notes: []}})}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id='contained-modal-title-vcenter'>
                                Ghi chú: {modalNote.subject.name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ul role='list' className='divide-y divide-gray-100 h-100 overflow-auto'
                                style={{ maxHeight: 500 }}>
                                {
                                    modalNote.subject.notes?.length > 0 ? modalNote.subject?.notes.map((note) => (
                                        <li key={note.date}
                                            className='flex flex-col max-w-full gap-x-6 py-2'>
                                            <div className={'d-flex flex-row justify-between'}>
                                                <div className='flex min-w-0 gap-x-4 flex-row items-center mt-1'>
                                                    <i className={'far fa-clock'}></i>
                                                    <p
                                                        className='text-xs/7 font-medium text-gray-900'>
                                                        {moment(note.date).format('dddd, DD/MM/YYYY')}
                                                    </p>
                                                </div>
                                                <button onClick={(e) => removeSubjectNote(e, note.subjectScheduleId)}
                                                        type='button'
                                                        className='text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-2 py-1 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900'>
                                                    <i className={'fas fa-trash me-1'}></i>
                                                    Xóa
                                                </button>
                                            </div>
                                            <div className='hidden shrink-0 sm:flex sm:flex-col'>
                                                <div className='min-w-0 flex-auto flex-wrap' style={{ maxWidth: 450 }}>
                                                    <p className='text-sm/6  text-gray-600'>{note.note}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )) : 'Chưa có ghi chú cho lịch học này!'
                                }
                            </ul>
                        </Modal.Body>
                        <Modal.Footer>
                            <div
                                onClick={() => {
                                    setModelNote({ isOpen: false, subject: {name: '', notes: []} });
                                }}>
                                <Button

                                    icon={<i className={'far fa-times-circle'}></i>}
                                    label={'Đóng'}
                                />
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={deleteItem}
            />

            <ConfirmDialog
                confirmDialog={confirmSubjectNoteDialog}
                setConfirmDialog={setConfirmSubjectNoteDialog}
                deleteItem={deleteScheduleSubjectNote}
            />
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default Schedule__Subject;
