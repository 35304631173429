// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';
import { _AUTH } from '../../../constants/_auth';

function TableSubjectTeachingExam(props) {
    let role = localStorage.getItem(_AUTH.ROLE) != null && localStorage.getItem(_AUTH.ROLE) != 'Teacher';

    function getTypeExam(typeExam) {
        switch (typeExam) {
            case  1:
                return 'Thường xuyên - Regular';
            case 2:
                return 'Giữa kỳ - Mid';
            case 3:
                return 'Cuối kỳ - Final';
            default:
                return '';
        }
    }

    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tên lịch thi
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Loại kỳ thi
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số lần thi
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ngày thi
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thời gian
                </th>

                <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Ghi chú
                </th>
                {role ? <th
                    scope='col'
                    className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Hành động
                </th> : ''}
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.id}
                >
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900 font-medium text-left'>
                            {item.name}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900 font-medium text-left'>
                            {getTypeExam(item.type)}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900 font-medium text-left'>
                            {item.count}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900 text-left'>
                            {item.startDate &&
                            moment(item.startDate).isValid() ? (
                                // @ts-ignore
                                <Moment format='DD/MM/YYYY'>
                                    {item.startDate}
                                </Moment>
                            ) : (
                                <div>{item.startDate}</div>
                            )}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                        <div className='text-sm text-gray-900 text-left'>
                            {moment(item.startDate + 'Z').format(
                                'HH:mm'
                            )} - {moment(item.endDate + 'Z').format(
                            'HH:mm'
                        )}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900 font-medium'>
                            {item.notes}
                        </div>
                    </td>
                    {role ? <td className='px-6 py-4 whitespace-nowrap text-right text-sm'>
                        <div className='flex items-center'>
                            {
                                item.questionSuiteId ? (item.numOfEasy + item.numOfNormal + item.numOfHard) ? <>
                                    <div
                                        className='text-green-700 hover:text-green-800 font-semibold cursor-pointer'
                                        onClick={(e) => {
                                            props.gotoViewResultExam(e, item);
                                        }}>
                                        <i className='far fa-eye mr-2' />
                                        Xem kết quả
                                    </div>
                                    <span className='mx-2'>/</span>
                                </> : '' : ''
                            }
                            <div
                                className='text-blue-700 hover:text-blue-800 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.clicked(e, item);
                                }}>
                                <i className='fas fa-user-edit mr-2' />
                                Chỉnh sửa
                            </div>
                            <span className='mx-2'>/</span>
                            <div
                                className='text-red-600 hover:text-red-700 font-semibold cursor-pointer'
                                onClick={(e) => {
                                    props.deleteClicked(e, item.id);
                                }}>
                                <i className='far fa-trash-alt mr-2' />
                                Xóa
                            </div>
                        </div>
                    </td> : ''}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableSubjectTeachingExam;
