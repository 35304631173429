// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';

function TableSubjectOnDayViewTypeGroupByDate(props) {
    return (
        <div>
            {
                props.data != null || props.data != {} ? <div className={'overflow-x-auto'}>
                    <h3 className={'text-uppercase py-3 font-bold text-center text-2xl'}>lịch học ngày {moment(props.dateChoose).format('DD/MM/YYYY')}</h3>
                    <div className={'overflow-x-auto'}>
                        <table className='w-full table-bordered divide-y divide-gray-900 border-2'
                               style={{ fontSize: 13, minWidth: 1000 }}>
                            <tbody>
                            <tr className={'bg-green-800 text-white'}>
                                <td>Ngày</td>
                                <td>Buổi</td>
                                {
                                    props.data?.length > 0 ? props.data?.map((val) => {
                                        return <td>Khoa: {val.facultyName} - Môn: {val.subjectName} - Lớp: {val.subjectTeachingName}</td>;
                                    }) : <td></td>
                                }
                            </tr>
                            <tr>
                                <td rowSpan={2}
                                    className={'font-bold p-2'}>{moment(props.dateChoose).format('dddd DD/MM/YYYY')}</td>
                                <td className={'bg-blue-100'}>S</td>
                                {
                                    props.data?.length > 0 ? props.data.map((val, index) => {
                                        return <td key={val.subjectTeachingName + '_morning_' + index}
                                                   className={'bg-blue-100 font-medium'}>{val.seasons[0].morning[0] != null ? `${' Phòng: ' + val.seasons[0].morning[0]['roomName']}` + `${' - GV: ' + val.seasons[0].morning[0]['teacherName']}` : ''}</td>;
                                    }) : <td>''</td>
                                }
                            </tr>
                            <tr>
                                <td className={'bg-yellow-50'}>C</td>
                                {
                                    props.data?.length > 0 ? props.data.map((val, index) => {
                                        return <td key={val.subjectTeachingName + '_afternoon_' + index}
                                                   className={'bg-yellow-50 font-medium'}>{val.seasons[0].afternoon[0] != null ? `${' Phòng: ' + val.seasons[0].afternoon[0]['roomName']}` + `${' - GV: ' + val.seasons[0].afternoon[0]['teacherName']}` : ''}</td>;
                                    }) : <td>''</td>
                                }
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div> : 'Không có dữ liệu lịch học!'
            }
        </div>
    );
}

export default TableSubjectOnDayViewTypeGroupByDate;
