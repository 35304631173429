import { Dialog, makeStyles } from '@material-ui/core';

export default function ErrorDialog(props) {
    const { errorDialog, setErrorDialog } = props;
    const classes = useStyles();

    function createMarkup() {
        return {
            __html: errorDialog.subTitle
        };
    }

    return (
        <Dialog open={errorDialog.isOpen} classes={{ paper: classes.dialog }}>
            <div className={classes.dialogTitle}>
                THÔNG BÁO
            </div>
            <div className={classes.dialogContent} dangerouslySetInnerHTML={createMarkup()}>
            </div>
            <div className={classes.dialogAction}>
                <div className={classes.buttonActionOk}
                     onClick={() => setErrorDialog({ ...errorDialog, isOpen: false })}>
                    <span>Đã hiểu!</span>
                </div>
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    dialog: {
        position: 'absolute'
    },
    dialogTitle: {
        textAlign: 'center'
        , background: 'crimson'
        , color: 'white'
        , fontSize: '20px'
        , padding: '5px 0px 5px 0px'
    },
    dialogContent: {
        textAlign: 'center'
        , padding: '20px'
        , fontSize: '20px'
        , whiteSpace: 'break-spaces'
    },
    dialogAction: {
        justifyContent: 'center'
        , display: 'flex'
        , padding: '10px 0px 20px 0px'
        , color: 'white'
    },
    buttonActionOk: {
        borderRadius: '5px'
        , backgroundColor: 'crimson'
        , padding: '5px 25px 5px 25px'
        , marginRight: '10px'
        , '&:hover': {
            cursor: 'pointer'
        }
    }
}));