import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultLayout from '../../../layouts/DefaultLayout/DefaultLayout';
import ErrorDialog from '../../../components/Elements/ErrorDialog/ErrorDialog';
import { setLoading } from '../../../redux/authSlice';
import { $api } from '../../../services/service';
import fileDownload from 'js-file-download';
import moment from 'moment/moment';

function ExamDetail() {
    let history = useHistory();

    const styleInput = 'px-2 py-1 w-full bg-white text-base font-semibold placeholder-slate-400' +
        ' focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500' +
        '      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none' +
        '      invalid:border-pink-500 invalid:text-pink-600' +
        '      focus:invalid:border-pink-500 focus:invalid:ring-pink-500 border-0' + '';
    // @ts-ignore
    let { examAttemptId } = useParams();
    const dispatch = useDispatch();
    const [filterType, setFilterType] = useState(99);
    const [change, setChange] = useState(false);
    const [questionDetail, setQuestionDetail] = useState({
        studentName: '',
        subjectTeachingExamName: '',
        result: 0,
        numberOfCorrectAnswer: 0,
        totalQuestions: 0,
        userInternalId: '',
        className: ''
    });
    const [questions, setQuestions] = useState([{
        id: '',
        questionSuiteId: '',
        questionText: '',
        level: 0,
        imageUrl: '',
        isEdit: false,
        levelText: '',
        questionAnswers: [
            {
                answerText: '',
                image: '',
                isAnswer: false,
                isCheck: false
            },
            {
                answerText: '',
                image: '',
                isAnswer: false,
                isCheck: false
            },
            {
                answerText: '',
                image: '',
                isAnswer: false,
                isCheck: false
            },
            {
                answerText: '',
                image: '',
                isAnswer: false,
                isCheck: false
            }
        ]
    }]);
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });

    async function getPagingQuestion() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.exam.examGetExamResultForOnline({ examAttemptId });
            if (res && res.result) {
                setQuestionDetail(res.result);
                setQuestions(res.result.questions);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }

    async function exportOnlineResultExam(exam: any) {
        const res: any = await $api.exam.examOnlineResultExport(examAttemptId);
        fileDownload(res, `Ket-qua-thi-${exam.studentName}-bai-thi-${exam.subjectTeachingExamName}-lop-${exam.className}-ngay-xuat-ket-qua-${moment((new Date())).format('DD/MM/YYYY')}.xlsx`);
    }

    useEffect(() => {
        getPagingQuestion();
    }, [change]);

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    return (
        <DefaultLayout>

            <div className='subject page position-relative'>
                <div className='page__header'>
                    <div className='row px-3'>
                        <div className={'d-flex cursor-pointer'} onClick={e => history.goBack()}>
                            <div className={'font-bold'}>
                                <i className={'fas fa-chevron-left pr-2'}></i>
                                Quay lại
                            </div>
                        </div>
                        <span className={'px-2'}>|</span>
                        <div className={'d-flex cursor-pointer'}>
                            <div className={'font-bold'}>
                                Chi tiết bài thi
                            </div>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='flex justify-content-end'>
                        <button type='button' onClick={() => exportOnlineResultExam(questionDetail)}
                                className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
                            <i className={'fas fa-file-alt mr-1'}></i> Xuất kết quả
                        </button>
                    </div>
                    <div className='flex flex-col justify-center w-full my-3'>
                        <div className={'font-bold text-3xl'}>Sinh viên: {questionDetail.studentName} -
                            Điểm: <span className={'text-red-600'}>{questionDetail.result}</span></div>
                        <div className={'font-bold text-2xl'}>MSSV: {questionDetail.userInternalId} -
                            Lớp: <span>{questionDetail.className}</span></div>
                        <div className={'font-bold text-xl'}>Trả lời
                            đúng: {questionDetail.numberOfCorrectAnswer} / {questionDetail.totalQuestions} (câu)
                        </div>
                        <div className={'font-bold text-xs'}>Bài thi: {questionDetail.subjectTeachingExamName}</div>
                    </div>
                    {
                        questions.length > 0 ?
                            questions.map((question, index) =>
                                <div
                                    key={question.id}
                                    className={'border-2 gap-2 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 p-3 mb-5'}>
                                    <div className={'d-flex justify-between align-items-center mb-2'}>
                                        <h5 className={'font-bold'}>Câu {index + 1}/{questions.length}</h5>
                                    </div>
                                    <div className={'row w-full-scroll'}>
                                        <div className='col-10'>
                                            <table className={'w-full'}>
                                                <tbody>
                                                <tr>
                                                    <td width={'5%'} className={'font-medium'}>Đáp án</td>
                                                    <td width={'10%'} className={'font-medium'}>Câu trả lời SV</td>
                                                    <td>
                                                        <p className={'text-left font-medium'}>Câu
                                                            hỏi: {question.questionText}</p>
                                                    </td>
                                                </tr>       
                                                {
                                                    question.questionAnswers.map((questionAnswer, answerIndex) =>
                                                        <tr className={questionAnswer.isCheck && questionAnswer.isAnswer ? 'bg-green-200' : questionAnswer.isCheck ? 'bg-red-200' : ''}>
                                                            <td>
                                                                <div className={'w-full flex justify-center'}>
                                                                    <img
                                                                        src={questionAnswer.isAnswer ? '/check.png' : '/uncheck.png'}
                                                                        className={'w-4 h-4'} alt='' />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={'w-full flex justify-center'}>
                                                                    <img
                                                                        src={questionAnswer.isCheck ? '/check.png' : '/uncheck.png'}
                                                                        className={'w-4 h-4'} alt='' />
                                                                </div>
                                                            </td>
                                                            <td><label
                                                                className='ml-2 mb-0 w-full text-base font-semibold text-gray-900 dark:text-gray-300'>
                                                                <div className={'d-flex items-center'}>
                                                            <span
                                                                className={'mr-1 '}>{answerIndex == 0 ? 'A' : answerIndex == 1 ? 'B' : answerIndex == 2 ? 'C' : 'D'}.</span>
                                                                    {questionAnswer.answerText}
                                                                </div>
                                                            </label></td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='col'>
                                            {
                                                question.imageUrl ? <>
                                                    <div className={'w-full d-flex justify-center  relative z-0'}>
                                                        <img id={'newQuestionImagePreview'} src={question.imageUrl}
                                                             alt='image'
                                                             className='w-full max-w-md mx-auto h-auto' />
                                                    </div>
                                                    <div className='text-center text-decoration-underline py-2'><i><u>Ảnh
                                                        câu
                                                        hỏi: {index + 1}/{questions.length}</u></i></div>
                                                </> : ''
                                            }
                                        </div>
                                    </div>
                                </div>) : <h6>Hiện chưa có câu hỏi!</h6>
                    }
                </div>
            </div>

            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}


export default ExamDetail;
