import Button from 'components/Elements/Button/Button';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import { $api } from 'services/service';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/authSlice';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { useParams } from 'react-router';
import TableQuestionSuite from '../../../components/Elements/Table/Table-Question-Suite';


function OnlineExam__List() {
    // @ts-ignore
    let { idSubjectTeaching } = useParams();
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState({ show: false, isUpdate: false });
    const [freeTextSearch, setFreeSearchText] = useState('');
    const [subject, setSubject] = useState({ name: '', subjectCode: '', id: '', facultyName: '' });
    const [newQuestionSuite, setNewQuestionSuite] = useState({
        name: '',
        subjectId: idSubjectTeaching
    });
    const [questionSuites, setQuestionSuites] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);

    async function saveQuestionSuite() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.question.saveQuestionSuite({
                ...newQuestionSuite,
                subjectId: idSubjectTeaching
            });
            if (res && res.result) {
                setModalShow({ show: false, isUpdate: false });
                setNewQuestionSuite({
                    name: '',
                    subjectId: idSubjectTeaching
                });
                getQuestionSuite();
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }

    async function getQuestionSuite() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.question.getQuestionSuite(idSubjectTeaching, freeTextSearch);
            if (res && res.result.items) {
                setQuestionSuites(res.result.items);
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }

    async function deleteQuestionSuite() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.question.deleteQuestionSuite(idDelete);
            if (res) {
                setIdDelete(null);
                setConfirmDialog({ isOpen: false, subTitle: '', title: '' });
                getQuestionSuite();
            }
            dispatch(setLoading(false));
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            setErrDialog({
                isOpen: true,
                subTitle: errMsg
            });
        }
    }

    useEffect(() => {
        async function getSubject() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.subject.getDetail(idSubjectTeaching);
                if (res && res.result) {
                    setSubject(res.result);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                setErrDialog({
                    isOpen: true,
                    subTitle: errMsg
                });
            }
        }

        getSubject();
        getQuestionSuite();
    }, []);

    function questionSuiteClick(id) {
        window.location.href = '/bo-cau-hoi/' + id;
    }

    function questionSuiteAction(question: object) {
        setModalShow({ show: true, isUpdate: Object.keys(question).length > 0 });
        if (question) {
            // @ts-ignore
            setNewQuestionSuite(() => question);
        }
    }

    function questionDelete(id) {
        setConfirmDialog({ isOpen: true, title: 'Xác nhận!', subTitle: 'Bạn muốn xóa bộ đề?' });
        setIdDelete(id);
    }

    return (
        <DefaultLayout>
            <div className='subject page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div>Quản lý bộ đề</div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <h2 className={'font-bold text-2xl'}>{subject.name}</h2>
                    <h4 className={'font-light text-sm mt-1'}>Khoa: {subject.facultyName} - Mã
                        KH: {subject.subjectCode}</h4>
                    <h2 className={'text-uppercase font-bold underline mt-5 mb-3'}>Quản lý bộ câu hỏi</h2>
                    <div className={'d-flex flex-row flex-nowrap justify-between'}>
                        <div className={'w-1/4'}>
                            <div className='relative mt-2 rounded-md shadow-sm'>
                                <input type='text'
                                       className='block w-full rounded-md border-0 py-2 pl-7  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                       placeholder='Tìm kiếm' maxLength={40}
                                       onChange={(e) => setFreeSearchText(e.target.value)}
                                       onKeyPress={e => e.charCode == 13 ? getQuestionSuite() : ''} />
                                <div onClick={() => getQuestionSuite()}
                                     className='absolute text-gray-500 inset-y-0 right-1 flex items-center m-2 p-2 w-1/12 bg-white'>
                                    <i className={'fas fa-search'}></i>
                                </div>
                            </div>
                        </div>
                        <Button color={'bg-blue-800'} hoverColor={'bg-blue-600'} label={'Tạo bộ câu hỏi'}
                                icon={<i className={'fas fa-plus'}></i>} click={e => questionSuiteAction({})} />
                    </div>
                    <h3 className={'font-medium mt-4 mb-2 underline'}>Tất cả bộ câu hỏi: <span
                        className={'text-danger text-lg'}>{questionSuites.length}</span> bộ</h3>
                    <TableQuestionSuite data={questionSuites} onClicked={(e, id) => questionSuiteClick(id)}
                                        updateClicked={(e, item) => questionSuiteAction(item)}
                                        deleteClicked={(e, id) => questionDelete(id)}></TableQuestionSuite>
                    <Modal
                        show={modalShow.show}
                        aria-labelledby='contained-modal-title-vcenter'
                        onHide={() => setModalShow({ show: false, isUpdate: false })}
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id='contained-modal-title-vcenter'>
                                {modalShow.isUpdate
                                    ? 'Cập nhật bộ câu hỏi'
                                    : 'Tạo mới bộ câu hỏi'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                    Tên bộ câu hỏi
                                </div>
                                <input
                                    name='text'
                                    type='text'
                                    required
                                    className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                    placeholder='Nhập tên bộ câu hỏi'
                                    value={newQuestionSuite.name}
                                    onChange={(e) => {
                                        setNewQuestionSuite({
                                            ...newQuestionSuite,
                                            name: e.target.value
                                        });
                                    }}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div
                                onClick={() => {
                                    saveQuestionSuite();
                                }}>
                                <Button
                                    label={modalShow.isUpdate ? 'Lưu lại' : 'Tạo mới'}
                                />
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
                deleteItem={() => deleteQuestionSuite()}
            />

            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}


export default OnlineExam__List;
