// @ts-ignore
import Moment from 'react-moment';
import moment from 'moment';
import React from 'react';

function TableStudentOnExam(props) {
    return (
        <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
            <tr>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Tên sinh viên
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Thời gian nộp bài
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số câu hỏi
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Số câu đúng
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    Điểm
                </th>
                <th
                    scope='col'
                    className='px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    hành động
                </th>
            </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 cursor-pointer'>
            {props.data.map((item, index) => (
                <tr
                    className='hover:bg-gray-100'
                    key={item.examAttemptId + index}
                   >

                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900 font-bold'>
                            {item.studentName || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {moment(item.submitDate).format('DD/MM/YYYY HH:mm') || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900'>
                            {item.totalQuestions || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-gray-900 font-bold'>
                            {item.numberOfCorrectAnswer || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        <div className='text-sm text-red-600 font-bold'>
                            {item.result || ''}
                        </div>
                    </td>
                    <td className='px-6 py-4 whitespace-nowrap'>
                        {
                            item.examAttemptId ? <div className='flex w-full h-full justify-center items-center'>
                                <div
                                    className='text-green-700 hover:text-green-800 font-semibold cursor-pointer'
                                    onClick={e => props.clicked(e, item.examAttemptId)}>
                                    <i className='far fa-eye mr-2' />
                                    Xem chi tiết
                                </div>
                                <span className='mx-2'>/</span>
                                <div
                                    className='text-blue-700 hover:text-blue-800 font-semibold cursor-pointer'
                                    onClick={(e) => {
                                        props.export(e, item);
                                    }}>
                                    <i className='fas fa-file-alt mr-2' />
                                    Xuất kết quả
                                </div>
                            </div> : <span className={'text-danger font-medium'}>Sinh viên không tham gia kỳ thi!</span>
                        }
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default TableStudentOnExam;
