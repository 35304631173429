import axiosClient from 'config/axiosClient';

const _url = '/api/settings';

const settingService = {
    settingsSchedule: (numberOfDays) => {
        return axiosClient.post(_url + '/schedule', { numberOfDays: numberOfDays });
    },
    getSettingsSchedule: () => {
        return axiosClient.post(_url + '/get-schedule', {});
    },
    settingsAttendance: (status) => {
        return axiosClient.post(_url + '/set-attendance', { value: status ? 1 : 0 });
    },
    getSettingAttendance: () => {
        return axiosClient.post(_url + '/get-attendance', {});
    }
};

export default settingService;
