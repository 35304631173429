// @ts-ignore
import Moment from 'react-moment';
import React, { useEffect, useRef, useState } from 'react';
import ErrorDialog from '../ErrorDialog/ErrorDialog';

function TableExam({ exams, changeData, onSaveResult }) {
    const styleInput = 'px-1.5 py-1 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400' +
        ' focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500' +
        '      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none' +
        '      invalid:border-pink-500 invalid:text-pink-600' +
        '      focus:invalid:border-pink-500 focus:invalid:ring-pink-500' + '';
    const ref = useRef(null);
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [examResults, setExamResults] = useState<any[]>([]);
    const fakeArray = ['1', '2', '3', '4'];
    let keycode = 0;
    useEffect(() => {
        setExamResults(() => exams);
    }, [exams]);

    async function saveResult(e) {
        onSaveResult(e, exams);
    }

    function inputChange(examIndex: number, resultIndex: number, resultKey: string, examRecord, examRecordIndex, event: React.ChangeEvent<HTMLInputElement>) {
        console.log(event.target.value);
        exams[examIndex].results[resultKey][examRecordIndex].result = event.target.value;
        changeData(exams);
        // 1. Make a shallow copy of the items
        let items = [...examResults];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...items[examIndex] };
        // 3. Replace the property you're intested in
        item.results[resultKey][examRecordIndex].result = event.target.value;
        // 4. Put it back into our array. N.B. we *are* mutating the array here,
        //    but that's why we made a copy first
        items[examIndex] = item;
        // 5. Set the state to our new copy
        setExamResults(() => items);
    }

    function handleKeyDown($event) {
        keycode = $event.keyCode;
    }

    return (
        <div ref={ref} className={'position-relative'}>
            <table className={'min-w-full table-exam table-bordered text-sm'}>
                {
                    examResults?.map((record, examIndex) => {
                            return <>
                                <tbody>
                                <tr>
                                    <td rowSpan='7' align={'center'} valign={'middle'}
                                        className={'font-bold align-middle'}><span
                                        className={'font-medium'}> MSSV: {record['userInternalId']}</span> <br /> <span
                                        style={{ fontSize: '1rem' }}>Họ
                                        tên: {record['studentName']}</span></td>
                                    <td className={'bg-blue-900 text-white font-bold'}>Kì thi</td>
                                    <td colSpan='4' className={'bg-blue-900 text-white font-bold'}>Số lần</td>
                                </tr>
                                {
                                    Object.keys(record.results)?.map((resultKey, resultIndex) => {
                                        return resultKey == 'Mid' && (record.results['Regular'] && record.results['Mid']) ?
                                            <>
                                                <tr>
                                                    <th scope='row'
                                                        className={'font-bold align-middle'} align={'center'}
                                                        valign={'middle'}>{resultKey == 'Mid' ? 'Giữa kỳ' : resultKey == 'Regular' ? 'Thường xuyên' : 'Cuối kì'}</th>
                                                    {/* Input nhập liệu */}
                                                    {
                                                        record.results[resultKey]?.map((examRecord, examRecordIndex) => {
                                                                return <td ><input type='text'
                                                                                  className={styleInput}
                                                                                  value={examRecord.result}
                                                                                   onChange={(event) => {
                                                                                       const value = event.target.value;
                                                                                       const isValid = /^$|^(10(\.0{0,1})?|[0-9](\.\d{0,1})?|\.([0-9]{1})?)$/.test(value);
                                                                                       console.log(isValid);
                                                                                       if (isValid) {
                                                                                           console.log(event);
                                                                                           inputChange(examIndex, resultIndex, resultKey, examRecord, examRecordIndex, event);
                                                                                       }else {
                                                                                           event.target.value = ''
                                                                                       }
                                                                                   }
                                                                                  }
                                                                                  onKeyDown={handleKeyDown}
                                                                /></td>;
                                                            }
                                                        )
                                                    }
                                                    {/*Input giả*/}
                                                    {
                                                        record.results[resultKey]?.length < 4 ?
                                                            fakeArray.splice(0, 4 - record.results[resultKey]?.length).map((examRecord, index) => {
                                                                    fakeArray.push(String(index));
                                                                    return <td><input type='text' className={styleInput}
                                                                                      value={'-'} readOnly={true} /></td>;
                                                                }
                                                            ) : ''
                                                    }
                                                </tr>
                                                <tr>
                                                    <td className={'font-bold'}>TBKT (hệ số 0.4)</td>
                                                    <td colSpan='4'
                                                        className={'text-red-600 text-center font-bold bg-gray-100'}>{record['midAvg']}</td>
                                                </tr>
                                            </> : <tr>
                                                <td className={'font-bold align-middle'} align={'center'}
                                                    valign={'middle'}>{resultKey == 'Mid' ? 'Giữa kỳ' : resultKey == 'Regular' ? 'Thường xuyên' : 'Cuối kì'}</td>
                                                {/* Input nhập liệu */}
                                                {
                                                    record.results[resultKey]?.map((examRecord, examRecordIndex) => {
                                                            return <td><input type='text'
                                                                              className={styleInput}
                                                                              value={examRecord.result}
                                                                              onChange={(event) => {
                                                                                  const value = event.target.value;
                                                                                  const isValid = /^$|^(10(\.0{0,1})?|[0-9](\.\d{0,1})?|\.([0-9]{1})?)$/.test(value);
                                                                                  console.log(isValid);
                                                                                  if (isValid) {
                                                                                      console.log(event);
                                                                                      inputChange(examIndex, resultIndex, resultKey, examRecord, examRecordIndex, event);
                                                                                  }else {
                                                                                      event.target.value = ''
                                                                                  }
                                                                              }
                                                                              }
                                                                              onKeyDown={handleKeyDown}
                                                            /></td>;
                                                        }
                                                    )
                                                }
                                                {/*Input giả*/}
                                                {
                                                    record.results[resultKey]?.length < 4 ?
                                                        fakeArray.splice(0, 4 - record.results[resultKey]?.length).map((examRecord, index) => {
                                                                fakeArray.push(String(index));
                                                                return <td><input type='text' className={styleInput}
                                                                                  value={'-'} readOnly={true} /></td>;
                                                            }
                                                        ) : ''
                                                }
                                            </tr>;
                                    })
                                }
                                {
                                    record.results['Final'] ?
                                        <tr>
                                            <td className={'font-bold'}>Điểm môn học</td>
                                            <td colSpan='4'
                                                className={'text-red-600 text-center font-bold bg-gray-100'}>{record['avg']}</td>
                                        </tr> : ''
                                }
                                </tbody>
                                <div className={'h-1 mb-2'}></div>
                            </>;
                        }
                    )}
            </table>
            <div className='fixed top-20 right-9 transform'>
                <button onClick={event => saveResult(event)}
                        className={' text-center py-2.5 px-3.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white whitespace-nowrap focus:outline-none bg-green-700 hover:bg-green-800'}>
                    <i className={'fas fa-save me-1'}></i> Lưu kết quả
                </button>
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </div>
    );
}

export default TableExam;
