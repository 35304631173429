import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DefaultLayout from 'layouts/DefaultLayout/DefaultLayout';
import React, { useEffect, useState } from 'react';
import Button from 'components/Elements/Button/Button';
import { $api } from 'services/service';
import { useHistory } from 'react-router-dom';
import TableUser from 'components/Elements/Table/Table-User';
import TableRoom from 'components/Elements/Table/Table-Room';
import Pagination from 'components/Elements/Pagination/Pagination';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { _AUTH } from 'constants/_auth';
import { setAlert, setLoading } from 'redux/authSlice';
import ConfirmDialog from 'components/Elements/ConfirmDialog/ConfirmDialog';
import ErrorDialog from 'components/Elements/ErrorDialog/ErrorDialog';
import { bool } from 'yup';

function User__List() {
    let history = useHistory();
    const dispatch = useDispatch();
    const [changeUser, setChangeUser] = useState(false);
    const [changesTeacher, setChangesTeacher] = useState(false);
    const [changesRoom, setChangesRoom] = useState(false);
    const [search, setSearch] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [users, setUsers] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [pagesUser, setPagesUser] = useState(0);
    const [pageUser, setPageUser] = useState(1);
    const [teachers, setTeachers] = useState([]);
    const [totalTeacher, setTotalTeacher] = useState(0);
    const [pagesTeacher, setPagesTeacher] = useState(0);
    const [pageTeacher, setPageTeacher] = useState(1);
    const [rooms, setRooms] = useState([]);
    const [totalRoom, setTotalRoom] = useState(0);
    const [pagesRoom, setPagesRoom] = useState(0);
    const [pageRoom, setPageRoom] = useState(1);
    const [nameRoom, setNameRoom] = useState('');
    const [numberSeat, setNumberSeat] = useState(40);
    const [modalRoomShow, setModalRoomShow] = useState(false);
    const [updateRoomMode, setUpdateRoomMode] = useState(false);
    const [faculties, setFaculties] = useState([]);
    const [faculty, setFaculty] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDeleteUser, setIdDeleteUser] = useState(null);
    const [idDeleteTeacher, setIdDeleteTeacher] = useState(null);
    const [idDeleteRooms, setIdDeletRooms] = useState(null);
    const [numberOfDays, setNumberOfDays] = useState('30');
    const [settingAttendance, setSettingAttendance] = useState(false);

    useEffect(() => {
        async function fetchUsers() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.user.getPaging({
                    pageIndex: pageUser,
                    pageSize: 9,
                    freeTextSearch: search || search.length > 0 ? search : null
                });
                setUsers(res.result.items);
                setTotalUser(res.result.totalRecord);
                setPagesUser(Math.ceil(res.result.totalRecord / 9));
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        fetchUsers();
    }, [changeUser]);

    useEffect(() => {
        async function fetchTeachers() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.teacher.getPaging({
                    pageIndex: pageTeacher,
                    pageSize: 9,
                    freeTextSearch: search || search.length > 0 ? search : null,
                    facultyId: faculty ? faculty : null
                });
                setTotalTeacher(res.result.totalRecord);
                setTeachers(res.result.items);
                setPagesTeacher(Math.ceil(res.result.totalRecord / 9));
                dispatch(setLoading(false));

            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        fetchTeachers();
    }, [changesTeacher, faculty]);

    useEffect(() => {
        async function getFaculties() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.faculties.getPaging({});
                if (res && res.result['items'].length > 0) {
                    setFaculties(res.result.items);
                    setFaculty(res?.result?.items[0].id);
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getFaculties();
    }, []);

    useEffect(() => {
        async function fetchRooms() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.room.getPaging({
                    pageIndex: pageRoom,
                    pageSize: 9,
                    freeTextSearch: search || search.length > 0 ? search : null
                });
                setTotalRoom(res.result.totalRecord);
                setRooms(res.result.items);
                setPagesRoom(Math.ceil(res.result.totalRecord / 9));
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        fetchRooms();
    }, [changesRoom]);

    useEffect(() => {
        async function getSetting() {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.setting.getSettingsSchedule();
                if (res && res.result) {
                    setNumberOfDays(() => res.result.numberOfDays);
                }

                const resAttendance: any = await $api.setting.getSettingAttendance();
                if (resAttendance && resAttendance.result) {
                    setSettingAttendance(() => Boolean(resAttendance.result.value));
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }
        getSetting();
    }, []);

    function getTextTab(index) {
        switch (index) {
            case 0:
                return 'cán bộ';
            case 1:
                return 'giảng viên';
            case 2:
                return 'phòng học';
        }
    }

    async function searchAPI() {
        switch (tabIndex) {
            case 0:
                return setChangeUser(!changeUser);
            case 1:
                return setChangesTeacher(!changesTeacher);
            case 2:
                return setChangesRoom(!changesRoom);
        }
    }

    function changePage(page) {
        switch (tabIndex) {
            case 0:
                setPageUser(page);
                return setChangeUser(!changeUser);
            case 1:
                setPageTeacher(page);
                return setChangesTeacher(!changesTeacher);
            case 2:
                setPageRoom(page);
                return setChangesRoom(!changesRoom);
        }
    }

    async function deleteUser(e, id) {
        if (id == localStorage.getItem(_AUTH.USERNAME) === id) {
            show_ErrMsg('Không thể xóa người dùng đang đăng nhập');
        } else {
            e.stopPropagation();
            setConfirmDialog({
                isOpen: true
                , title: 'Thông báo'
                , subTitle: 'Bạn có chắc muốn xóa ?'
            });
            setIdDeleteUser(id);
        }
    }

    async function deleteItemUser() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.user.deleteUser(idDeleteUser);
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                setChangeUser(!changeUser);
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function deleteTeacher(e, id) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDeleteTeacher(id);
    }

    async function deleteItemTeacher() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.teacher.deleteTeacher(idDeleteTeacher);
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                setChangesTeacher(!changesTeacher);
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function deleteRoom(e, id) {
        e.stopPropagation();
        setConfirmDialog({
            isOpen: true
            , title: 'Thông báo'
            , subTitle: 'Bạn có chắc muốn xóa ?'
        });
        setIdDeletRooms(id);
    }

    async function deleteItemRooms() {
        setConfirmDialog({ isOpen: false, title: '', subTitle: '' });
        try {
            dispatch(setLoading(true));
            const res: any = await $api.room.deleteRoom(idDeleteRooms);
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                setChangesRoom(!changesRoom);
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function addNew(e) {
        e.stopPropagation();
        switch (tabIndex) {
            case 0:
                return history.push(`/quan-ly-chung/them-moi`);
            case 1:
                return history.push(`/quan-ly-chung/them-moi?r=2`);
            case 2:
                return openCreateRoom();
        }
    }

    function openCreateRoom() {
        setNameRoom('');
        setNumberSeat(40);
        setModalRoomShow(true);
    }

    async function createRoom() {
        try {
            if (nameRoom.length <= 0) {
                show_ErrMsg('Tên phòng không được bỏ trống');
                return;
            }
            const res: any = await $api.room.createRoom({
                name: nameRoom,
                numberOfSeats: numberSeat
            });
            if (res) {
                dispatch(
                    setAlert({
                        title: `Tạo thành công phòng ${nameRoom}`
                    })
                );
                setNameRoom('');
                setChangesRoom(!changesRoom);
            }
            setModalRoomShow(false);
        } catch (e) {
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        } finally {
            setNameRoom('');
            setNumberSeat(40);
        }
    }

    async function openUpdateRoom(e, id, name, numberSeat) {
        e.stopPropagation();

        try {
            setNameRoom(name);
            setNumberSeat(numberSeat);
            setModalRoomShow(true);
            setUpdateRoomMode(id);
        } catch (e) {
        }
    }

    async function updateRoom() {
        try {
            if (nameRoom.length <= 0) {
                show_ErrMsg('Tên phòng không được bỏ trống');
                return;
            }
            const res: any = await $api.room.updateRoom({
                id: updateRoomMode,
                name: nameRoom,
                numberOfSeats: numberSeat
            });
            if (res) {
                dispatch(
                    setAlert({
                        title: `Cập nhật thành công phòng ${nameRoom}`
                    })
                );
                setNameRoom('');
                setNumberSeat(40);
                setChangesRoom(!changesRoom);
            }
            setModalRoomShow(false);
            setUpdateRoomMode(false);
        } catch (e) {
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    function getDetail(e, id) {
        e.stopPropagation();
        switch (tabIndex) {
            case 0:
                return history.push(`/quan-ly-chung/${id}`);
            case 1:
                return history.push(`/quan-ly-chung/${id}?r=2`);
        }
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function saveSetting() {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.setting.settingsSchedule(numberOfDays);
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    async function settingAttendanceChange(status: boolean) {
        try {
            dispatch(setLoading(true));
            const res: any = await $api.setting.settingsAttendance(status);
            if (res) {
                dispatch(
                    setAlert({
                        title: res.message
                    })
                );
                dispatch(setLoading(false));
                setSettingAttendance(() => status)
            }
        } catch (e) {
            dispatch(setLoading(false));
            // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
            let errMsg;
            let type = typeof e?.response?.data?.errors?.exceptionMessage;
            if (type === 'string') {
                errMsg = e?.response?.data?.errors?.exceptionMessage;
            } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
            } else {
                errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
            }
            show_ErrMsg(errMsg);
        }
    }

    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div>Quản lý chung</div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='flex justify-between items-end flex-wrap mb-4' hidden={tabIndex === 3}>
                        <div className='actions--item' onClick={addNew}>
                            <Button
                                label={'Thêm ' + getTextTab(tabIndex)}
                                icon={<i className='fas fa-user-plus' />}
                            />
                        </div>
                        <div className='action--item search flex justify-start mt-4 items-end'>
                            {tabIndex === 1 ? (
                                <div className='mr-4'>
                                    <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                                        Khoa
                                    </div>
                                    <select
                                        className='w-60 block px-3 py-2 border border-gray-300
													placeholder-gray-500 rounded-md
													focus:outline-none focus:ring-1
													focus:ring-blue-700'
                                        value={faculty}
                                        onChange={(e) => {
                                            setFaculty(e.target.value);
                                        }}>
                                        {faculties.map((item: any) => {
                                            return (
                                                <option
                                                    value={item.id}
                                                    key={item.id}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            ) : (
                                ''
                            )}
                            <input
                                name='search'
                                type='text'
                                required
                                className='mr-4 w-80 input-custom appearance-none block
								 	px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                                placeholder={
                                    'Tìm kiếm ' + getTextTab(tabIndex)
                                }
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) =>
                                    e.key === 'Enter' && searchAPI()
                                }
                            />
                            <div onClick={searchAPI}>
                                <Button
                                    label='Tìm kiếm'
                                    color='bg-yellow-500'
                                    hoverColor='bg-yellow-400'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='tab-content'>
                        <Tabs
                            defaultIndex={0}
                            onSelect={(index) => setTabIndex(index)}>
                            <TabList>
                                <Tab>Cán bộ</Tab>
                                <Tab>Giảng viên</Tab>
                                <Tab>Phòng học</Tab>
                                <Tab>Tính Năng Nâng Cao</Tab>
                            </TabList>
                            <TabPanel>
                                <div>
                                    {
                                        users.length === 0 ?
                                            <div className='flex flex-wrap -ml-4 -mb-4'
                                                 style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <img src='/notFound_01.png'
                                                     alt='Không tìm thấy dữ liệu'
                                                     title='Không tìm thấy dữ liệu'
                                                />
                                            </div>
                                            :
                                            <div className='w-auto-scroll'>
                                                <div className='table w-auto-scroll mt-8 w-full'>
                                                    <TableUser
                                                        data={users}
                                                        roleShow={true}
                                                        deleteClicked={deleteUser}
                                                        clicked={getDetail}
                                                        updateClicked={getDetail}
                                                    />
                                                </div>
                                            </div>
                                    }
                                    <div className='pagination'>
                                        <Pagination
                                            page={pageUser}
                                            total={totalUser}
                                            pages={pagesUser}
                                            clickedPage={changePage}
                                        />
                                    </div>
                                </div>
                                <ConfirmDialog
                                    confirmDialog={confirmDialog}
                                    setConfirmDialog={setConfirmDialog}
                                    deleteItem={deleteItemUser}
                                />
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    {
                                        teachers.length === 0 ?
                                            <div className='faculties--list flex flex-wrap -ml-4 -mb-4'
                                                 style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <img src='/notFound_01.png'
                                                     alt='Không tìm thấy dữ liệu'
                                                     title='Không tìm thấy dữ liệu'
                                                />
                                            </div>
                                            :
                                            <div className='w-auto-scroll'>
                                                <div className='table w-auto-scroll mt-8 w-full'>
                                                    <TableUser
                                                        data={teachers}
                                                        roleShow={true}
                                                        deleteClicked={deleteTeacher}
                                                        clicked={getDetail}
                                                        updateClicked={getDetail}
                                                    />
                                                </div>
                                            </div>
                                    }
                                    <div className='pagination'>
                                        <Pagination
                                            page={pagesTeacher}
                                            total={totalTeacher}
                                            pages={pagesTeacher}
                                            clickedPage={changePage}
                                        />
                                    </div>
                                </div>
                                <ConfirmDialog
                                    confirmDialog={confirmDialog}
                                    setConfirmDialog={setConfirmDialog}
                                    deleteItem={deleteItemTeacher}
                                />
                            </TabPanel>
                            <TabPanel>
                                <div>
                                    {
                                        rooms.length === 0 ?
                                            <div className='faculties--list flex flex-wrap -ml-4 -mb-4'
                                                 style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <img src='/notFound_01.png'
                                                     alt='Không tìm thấy dữ liệu'
                                                     title='Không tìm thấy dữ liệu'
                                                />
                                            </div>
                                            :
                                            <div className='w-auto-scroll'>
                                                <div className='table w-auto-scroll mt-8 w-full'>
                                                    <TableRoom
                                                        data={rooms}
                                                        deleteClicked={deleteRoom}
                                                        updateClicked={
                                                            openUpdateRoom
                                                        }
                                                        clicked={openUpdateRoom}
                                                    />
                                                </div>
                                            </div>
                                    }

                                    <div className='pagination'>
                                        <Pagination
                                            page={pagesRoom}
                                            total={totalRoom}
                                            pages={pagesRoom}
                                            clickedPage={changePage}
                                        />
                                    </div>
                                </div>
                                <ConfirmDialog
                                    confirmDialog={confirmDialog}
                                    setConfirmDialog={setConfirmDialog}
                                    deleteItem={deleteItemRooms}
                                />
                            </TabPanel>
                            <TabPanel>
                                <div className='actions flex items-center  justify-between'>
                                    <div className='flex flex-column justify-between items-start w-100'>
                                        <div className='mr-4 py-2'>
                                            <div className='custom-control custom-switch'>
                                                <input type='checkbox' className='custom-control-input'
                                                       id='customSwitch1'
                                                       checked={settingAttendance}
                                                       onChange={event => settingAttendanceChange(event.target.checked)} />
                                                <label className='custom-control-label' htmlFor='customSwitch1'>Cho phép
                                                    điểm danh</label>
                                            </div>
                                        </div>
                                        <div className='mr-4'>
                                            <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>Số ngày
                                                hiển
                                                thị thời khóa biểu cho SV
                                            </div>
                                            <select value={numberOfDays}
                                                    onChange={event => setNumberOfDays(() => event.target.value)}
                                                    className='w-60 block px-3 py-2 border border-gray-300placeholder-gray-500 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-700'>
                                                <option value='7'>7</option>
                                                <option value='15'>15</option>
                                                <option value='30'>30</option>
                                                <option value='45'>45</option>
                                                <option value='60'>60</option>
                                            </select>
                                            <button className={'btn btn-success mt-2'} onClick={() => saveSetting()}><i
                                                className={'far fa-save'}></i> Lưu
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>

            <Modal
                show={modalRoomShow}
                aria-labelledby='contained-modal-title-vcenter'
                onHide={() => setModalRoomShow(false)}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        {updateRoomMode ? 'Cập nhật phòng' : 'Tạo mới phòng'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-4'>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                            Tên phòng
                        </div>
                        <input
                            name='text'
                            type='text'
                            required
                            className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                            placeholder='Nhập tên phòng'
                            value={nameRoom}
                            onChange={(e) => {
                                setNameRoom(e.target.value);
                            }}
                        />
                    </div>
                    <div>
                        <div className='pl-1 text-sm font-semibold mb-2 text-gray-600 '>
                            Số lượng ghế
                        </div>
                        <input
                            name='text'
                            type='number'
                            required
                            className='appearance-none block
									w-full px-3 py-2 border border-gray-300
									placeholder-gray-500 rounded-md
									focus:outline-none focus:ring-1
									focus:ring-blue-700'
                            placeholder='Nhập số lượng ghế'
                            value={numberSeat}
                            onChange={(e) => {
                                setNumberSeat(Number(e.target.value));
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        onClick={(e) => {
                            updateRoomMode ? updateRoom() : createRoom();
                        }}>
                        <Button
                            label={updateRoomMode ? 'Lưu lại' : 'Tạo mới'}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default User__List;