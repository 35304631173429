import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/authSlice';
import { $api } from '../../../services/service';
import DefaultLayout from '../../../layouts/DefaultLayout/DefaultLayout';
import Pagination from '../../../components/Elements/Pagination/Pagination';
import ErrorDialog from '../../../components/Elements/ErrorDialog/ErrorDialog';
import { useHistory, useParams } from 'react-router';
import TableStudentOnExam from '../../../components/Elements/Table/Table-Student-On-Exam';
import moment from 'moment';
import fileDownload from 'js-file-download';

function ExamView() {
    // @ts-ignore
    let { examId } = useParams();

    let history = useHistory();
    const dispatch = useDispatch();

    const [students, setStudents] = useState([]);
    const [classId, setClassId] = useState('');
    const [changes, setChanges] = useState(false);
    const [graduated, setGraduated] = useState('0');
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
    const [errDialog, setErrDialog] = useState({ isOpen: false, subTitle: '' });
    const [idDelete, setIdDelete] = useState(null);

    useEffect(() => {
        async function getExam(option = {}) {
            try {
                dispatch(setLoading(true));
                const res: any = await $api.exam.examGetListExamResultForOnline(option);
                if (res && res.result) {
                    setTotal(res.result.totalRecord);
                    setStudents(res.result.items);
                    setPages(Math.ceil(res.result.totalRecord / 18));
                }
                dispatch(setLoading(false));
            } catch (e) {
                dispatch(setLoading(false));
                // show_ErrMsg(e?.response?.data?.errors?.exceptionMessage ? e?.response?.data?.errors?.exceptionMessage.title : 'Đã có lỗi xảy ra, xin vui lòng thử lại');
                let errMsg;
                let type = typeof e?.response?.data?.errors?.exceptionMessage;
                if (type === 'string') {
                    errMsg = e?.response?.data?.errors?.exceptionMessage;
                } else if (e?.response?.data?.errors?.exceptionMessage?.title) {
                    errMsg = e?.response?.data?.errors?.exceptionMessage?.title;
                } else {
                    errMsg = 'Đã có lỗi xảy ra, xin vui lòng thử lại';
                }
                show_ErrMsg(errMsg);
            }
        }

        getExam({
            pageIndex: page,
            pageSize: 18,
            subjectTeachingExamId: examId
        });
    }, [changes]);

    function changePage(page) {
        setPage(page);
        setChanges(!changes);
    }

    function show_ErrMsg(msg) {
        setErrDialog({
            isOpen: true
            , subTitle: msg
        });
    }

    async function exportOnlineResultExam(exam: any) {
       if(exam.examAttemptId){
           const res: any = await $api.exam.examOnlineResultExport(exam.examAttemptId);
           fileDownload(res, `Ket-qua-thi-${exam.studentName}-bai-thi-${exam.subjectTeachingExamName}-lop-${exam.className}-ngay-xuat-ket-qua-${moment((new Date())).format('DD/MM/YYYY')}.xlsx`);
       }else{
           show_ErrMsg('Sinh viên không tham gia buổi thi!')
       }
    }

    async function exportAllOnlineResultExam(examId: any) {
        const res: any = await $api.exam.examOnlineResultExportAll(students?.[0].subjectTeachingExamId);
        // fileDownload(res, `Ket-qua-thi-${exam.studentName}-bai-thi-${exam.subjectTeachingExamName}-lop-${exam.className}-ngay-xuat-ket-qua-${moment((new Date())).format('DD/MM/YYYY')}.xlsx`);
        fileDownload(res, `Tat-ca-ket-qua-thi-${students?.[0].subjectTeachingExamName}-ngay-xuat-ket-qua-${moment((new Date())).format('DD/MM/YYYY')}.xlsx`);
    }
    return (
        <DefaultLayout>
            <div className='page'>
                <div className='page__header'>
                    <div className='___title flex items-center'>
                        <div className='___title__decorate' />
                        <div className='___title__box'>
                            <a href={'#'} onClick={event => history.goBack()}>
                                Chi tiết lịch thi
                            </a>
                        </div>
                    </div>
                </div>
                <div className='page__content bg-white mt-3'>
                    <div className='mt-4'>
                        {
                            students.length > 0 ? <div className={'flex justify-between align-middle w-full'}>
                                <div className='col'>
                                    <div className='flex flex-col justify-center w-full'>
                                        <div>
                                            <h2 className={'font-bold text-2xl'}>Lịch
                                                thi: {students?.[0].subjectTeachingExamName}</h2>
                                            <h2 className={'font-bold text-xs'}>Bộ câu
                                                hỏi: {students?.[0].questionSuiteName}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='row justify-end'>
                                        <button type='button'
                                                onClick={() => exportAllOnlineResultExam(students?.[0].subjectTeachingExamId)}
                                                className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
                                            <i className={'fas fa-file-alt mr-1'}></i> Xuất tất cả kết quả
                                        </button>
                                    </div>
                                </div>
                            </div> : ''
                        }
                        <div className='w-auto-scroll'>
                            {
                                students.length === 0 ?
                                    <div className='flex flex-wrap -ml-4 -mb-4'
                                         style={{ justifyContent: 'center', alignItems: 'center' }}>
                                        <img src='/notFound_01.png'
                                             alt='Không tìm thấy dữ liệu'
                                             title='Không tìm thấy dữ liệu'
                                        />
                                    </div> :
                                    <div className='table w-auto-scroll mt-8 w-full'>
                                        <TableStudentOnExam data={students}
                                                            export={(e, exam) => exportOnlineResultExam(exam)}
                                                            clicked={(e, examAttemptId) => examAttemptId ? history.push('/chi-tiet-bai-thi/' + examAttemptId) : show_ErrMsg('Sinh viên không tham gia buổi thi!')}></TableStudentOnExam>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className='pagination'>
                        <Pagination
                            page={page}
                            total={total}
                            pages={pages}
                            clickedPage={changePage}
                        />
                    </div>
                </div>
            </div>
            <ErrorDialog
                errorDialog={errDialog}
                setErrorDialog={setErrDialog}
            />
        </DefaultLayout>
    );
}

export default ExamView;